import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from './pages/Login'
import Home from './pages/Home'
import Clientes from './pages/Clientes'
import Hospedagens from './pages/Hospedagens'
import Layout from './components/Layout'

import { ProtectedRoute } from "./components/protected.route";


const Routes = () => {

    return (

        <Switch>

            <Route path='/' exact component={Login} />

            <Layout>

                <ProtectedRoute path='/Home' exact component={Home} />
                <ProtectedRoute path='/Clientes' exact component={Clientes} />
                <ProtectedRoute path='/Hospedagens' exact component={Hospedagens} />

            </Layout>
            
        </Switch>

    )


}


export default Routes