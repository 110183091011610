import axios from 'axios'

const api = axios.create({
    baseURL: 'https://apihospedagem.koderia.com.br',
    
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': '!nf0$@ud#'
    }
})

export default api
