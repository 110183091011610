import React, { useEffect, useState } from 'react'



const Home = () => {


    useEffect(() => {



    }, [])

    
    return (
        <div>

            <div class="content-overlay"></div>

            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="content-header">Home</div>
                    </div>
                </div>
                <section id="extended">
                    
                
                </section>
            </div>
           

        </div>
           
    )
}

export default Home