import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'


const Layout = (props) => {

    var [nomeUsuario, setNomeUsuario] = useState(sessionStorage.getItem('nome'))

    var history = useHistory()


    function logout() {

        localStorage.clear()
        history.push('/')

    }


    return (
        
            <body class="vertical-layout vertical-menu 2-columns  navbar-sticky" data-menu="vertical-menu" data-col="2-columns">

                <nav class="navbar navbar-expand-lg navbar-light header-navbar navbar-fixed">
                    <div class="container-fluid navbar-wrapper">
                        <div class="navbar-header d-flex">
                        <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse">
                            <i class="ft-menu font-medium-3"></i></div>                
                        </div>
                        <div class="navbar-container">
                        <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
                            <ul class="navbar-nav">
                            
                                <li class="dropdown nav-item mr-1"><a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2" href="javascript:;" data-toggle="dropdown">
                                    <div class="user d-md-flex d-none mr-2"><span class="text-right">John Doe</span><span class="text-right text-muted font-small-3">Available</span></div><img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35"/></a>
                                    <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"><a class="dropdown-item" href="app-chat.html">
                                        <div class="d-flex align-items-center"><i class="ft-message-square mr-2"></i><span>Chat</span></div></a><a class="dropdown-item" href="page-user-profile.html">
                                        <div class="d-flex align-items-center"><i class="ft-edit mr-2"></i><span>Edit Profile</span></div></a><a class="dropdown-item" href="app-email.html">
                                        <div class="d-flex align-items-center"><i class="ft-mail mr-2"></i><span>My Inbox</span></div></a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" onClick={() => logout()}>
                                        <div class="d-flex align-items-center">
                                            <i class="ft-power mr-2"></i><span>Logout</span>
                                        </div>
                                    </a>
                                    </div>
                                </li>
                            
                            </ul>
                        </div>
                        </div>
                    </div>
                </nav> 

                <div class="wrapper">


                    <div class="app-sidebar menu-fixed" data-background-color="white" data-image="assets/img/sidebar-bg/01.jpg" data-scroll-to-active="false">
                        <div class="sidebar-header">
                            <div class="logo clearfix">
                                <a class="logo-text float-left">
                                    <div class="logo-img">
                                        <img src="assets/img/logo.png" alt="Apex Logo"/>
                                    </div>
                                    <span class="text">Koderia</span>
                                </a>
                                <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;">
                                    <i class="ft-x"></i>
                                </a>
                            </div>
                        </div>

                        <div class="sidebar-content main-menu-content">
                            <div class="nav-container">
                                <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

                                    <li class="nav-item">
                                        <Link to='/Clientes'>
                                            <i class="ft-users"></i><span class="menu-title">Cliente</span>
                                        </Link>                                
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/Hospedagens'>
                                            <i class="ft-server"></i><span class="menu-title">Hospedagem</span>
                                        </Link>                                
                                    </li>
                                    <li class="nav-item">
                                        <a onClick={() => logout()}>
                                            <i class="ft-log-out"></i><span class="menu-title">Logout</span>
                                        </a>                                
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="sidebar-background"></div>
                    
                    </div>

                    <div class="main-panel">

                    
                        <div class="main-content">

                            {props.children}
                            
                        </div>

                        <footer class="footer undefined undefined">
                        <p class="clearfix text-muted m-0"><span>Copyright  &copy; 2021 &nbsp;</span><a href="" id="" target="_blank">KODERIA</a><span class="d-none d-sm-inline-block"></span></p>
                        </footer>

                        <button class="btn btn-primary scroll-top" type="button"><i class="ft-arrow-up"></i></button>

                    </div>

                </div>

                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>

            </body>

        
    )

}

export default Layout