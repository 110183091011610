import React, { useEffect, useState } from 'react'
import api from '../components/api'
import Modal from '../modals/hospedagemModal'


const Hospedagens = () => {

    var [hospedagens, setHospedagens] = useState('')
    var [hospedagem_id, setHospedagem_id] = useState('')


    function alertaSalvo(){
        
        window.toastr.success("Registro salvo com sucesso!", "Salvo!")
        
    }

    function alertaRemovido(){
        
        window.toastr.success("Registro removido com sucesso!", "Removido!")
        
    }

    function alertaErro(erro){

        var mensagemErro = ''

        if (erro == "salvar"){
            mensagemErro = "Erro ao salvar o registro!"
        }

        if(erro == "remover"){
            mensagemErro = "Erro ao remover o registro!"
        }
        
        window.toastr.error(mensagemErro, "Erro!")
        
    }
    

    async function listaHospedagens(){


        await api.get('hospedagemListaTodos').then((response) => {

            if (response.data) {

                console.log(response.data)

                
				setHospedagens(response.data.map((rs) => 

                    <tr>
						<td>{rs.url}</td>
                        <td>{rs.painelUrl}</td>
                        <td>{rs.painelUsuario}</td>
                        <td>{rs.painelSenha}</td>						

                        <td class="text-truncate">
                            <a class="success p-0"
                                data-toggle="modal" 
                                aria-haspopup="true" 
                                aria-expanded="false" 
                                data-target=".text-left-Hospedagem"
                                onClick={() => setHospedagem_id(rs.hospedagem_id)}
                                key={hospedagem_id}
                                >

                                <i class="ft-edit-2 font-medium-3 mr-2"></i>
                            </a>

                            <a class="danger p-0"
                                onClick={() => apagaHospedagem(rs.hospedagem_id)}
                                >
                                <i class="ft-x font-medium-3"></i>
                            </a>
                        </td>
                        
					</tr>))
            

        }
           

        }).catch((err) => {
            
            console.log(err)

        })

    


    }

    function apagaHospedagem(hospedagem_id){

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma exclusão?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
            
        }).then(function(t) {

            async function apagaReg(){

                await api.delete(`hospedagem/${hospedagem_id}`).then((response) => {
        
                    listaHospedagens()           
        
                }).catch((err) => {
                    
                    console.log(err)
        
                })
        
            }

            apagaReg()

        })

    }


    useEffect(() => {

        listaHospedagens()

    }, [])

    
    return (
        <div>

            

            <div class="content-overlay"></div>

            <button type="button" class="btn btn-outline-success mb-1 mr-1 alertaSalvo" id="type-success" style={{ 'display' : 'none' }}>Salvo com sucesso!</button>

            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="content-header">Hospedagens</div>
                    </div>
                </div>
                <section id="extended">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">

                                <button type="button" 
                                    class="btn btn-info mr-2" 
                                    data-toggle="modal" 
                                    aria-haspopup="true" 
                                    aria-expanded="false" 
                                    data-target=".text-left-Hospedagem"
                                    onClick={() => setHospedagem_id()}
                                    >

                                    <i class="ft-plus-square mr-1"></i> Incluir

                                </button>

                                </div>
                                <div class="card-content">
                                    <div class="card-body table-responsive">
                                        <table class="table table-striped m-0">
                                        <thead>
                                            <tr>
                                                <th>Url</th>
                                                <th>Painel</th>
                                                <th>Usuário</th>
                                                <th>Senha</th>                                                
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {hospedagens}
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </section>
            </div>

            
            <Modal class="modal fade text-left-Hospedagem" 
                tabindex="-1" 
                role="dialog"    
                aria-hidden="true"
                listaHospedagens={listaHospedagens}
                alertaSalvo={alertaSalvo}
                alertaErro={alertaErro}
                alertaRemovido={alertaRemovido}
                hospedagem_id={hospedagem_id}
                />
            

        </div>
           
    )
}

export default Hospedagens