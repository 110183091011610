import React, { useEffect, useState } from 'react'
import axios from 'axios'
import api from '../components/api'

const Modal = (props) => {

    var erro = ''
    var erroMensagem = ''

    var hospedagem_id = props.hospedagem_id

    var [clientes, setClientes] = useState()
    
    var [cliente_id, setCliente_id] = useState('')
    var [url, setUrl] = useState('')
    var [painelUrl, setPainelUrl] = useState('')
    var [painelUsuario, setPainelUsuario] = useState('')
    var [painelSenha, setPainelSenha] = useState('')
    var [ftpUrl, setFtpUrl] = useState('')
    var [ftpUsuario, setFtpUsuario] = useState('')
    var [ftpSenha, setFtpSenha] = useState('')
    var [bancoUrl, setBancoUrl] = useState('')
    var [bancoPorta, setBancoPorta] = useState('')
    var [bancoUsuario, setBancoUsuario] = useState('')
    var [bancoSenha, setBancoSenha] = useState('')
    var [registroUsuario, setRegistroUsuario] = useState('')
    var [registroSenha, setRegistroSenha] = useState('')
    var [observacao, setObservacao] = useState('')
    var [ad_new, setAd_new] = useState('')
    var [ad_upd, setAd_upd] = useState('')
    

    console.log('hospedagem_id: ' + props.hospedagem_id)


    async function listaClientes() {

        await api.get('clienteListaTodos').then((response) => {
    
            setClientes(response.data.map((rs) => <option key={rs.cliente_id} value={rs.cliente_id}>{rs.nome}</option>))

        }).catch((err) => {
            console.log(err)
        })

    }


    async function carregaDados() {

        if (hospedagem_id) {

            await api.get(`hospedagemListaUm/${hospedagem_id}`).then((response) => {

                var data = response.data
    
                setCliente_id(data[0].cliente_id)
                setUrl(data[0].url)
                setPainelUrl(data[0].painelUrl)
                setPainelUsuario(data[0].painelUsuario)
                setPainelSenha(data[0].painelSenha)
                setFtpUrl(data[0].ftpUrl)
                setFtpUsuario(data[0].ftpUsuario)
                setFtpSenha(data[0].ftpSenha)
                setBancoUrl(data[0].bancoUrl)
                setBancoPorta(data[0].bancoPorta)
                setBancoUsuario(data[0].bancoUsuario)
                setBancoSenha(data[0].bancoSenha)
                setRegistroUsuario(data[0].registroUsuario)
                setRegistroSenha(data[0].registroSenha)
                setObservacao(data[0].observacao)
                setAd_new(data[0].ad_new)
                setAd_upd(data[0].ad_upd)
               
            }).catch((err) => {
                
                console.log(err.response)
    
            })

        } else {

            limpaDados()

        }


    }


    function limpaDados(){

        setCliente_id('')
        setUrl('')
        setPainelUrl('')
        setPainelUsuario('')
        setPainelSenha('')
        setFtpUrl('')
        setFtpUsuario('')
        setFtpSenha('')
        setBancoUrl('')
        setBancoPorta('')
        setBancoUsuario('')
        setBancoSenha('')
        setRegistroUsuario('')
        setRegistroSenha('')
        setObservacao('')
        setAd_new('')
        setAd_upd('')

    }

    function salva(){

        //trata erros

        if(cliente_id === ''){
            erro = 'cliente'
            erroMensagem = 'O campo "Cliente" não pode ficar em branco!'
        }

        if(url === ''){
            erro = 'url'
            erroMensagem = 'O campo "Url" não pode ficar em branco!'
        }


        if(erro){

           window. Swal.fire({
                title: "Erro!",
                text: erroMensagem,
                type: "error",
                confirmButtonClass: "btn btn-secondary",
                buttonsStyling: !1
            })

            return false
        }  
        

        var dataPost = {

            cliente_id: cliente_id,
            url: url,
            painelUrl: painelUrl,
            painelUsuario: painelUsuario,
            painelSenha: painelSenha,
            ftpUrl: ftpUrl,
            ftpUsuario: ftpUsuario,
            ftpSenha: ftpSenha,
            bancoUrl: bancoUrl,
            bancoPorta: bancoPorta,
            bancoUsuario: bancoUsuario,
            bancoSenha: bancoSenha,
            registroUsuario: registroUsuario,
            registroSenha: registroSenha,
            observacao: observacao

        }

        

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma salvar?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1
        }).then(function(t) {
            
            console.log(dataPost)

            async function salvaReg() {

                if (hospedagem_id) {

                    await api.put(`hospedagem/${hospedagem_id}`, dataPost).then((response) => {

                        props.listaHospedagens()
                        props.alertaSalvo()
                        
                        window.$('.fecharModal').click()
                                                    
                    }).catch((err) => {
                        
                        props.alertaErro('salvar')

                        console.log(err.response)
                        return false

                    })

                }else{

                    await api.post('hospedagem', dataPost).then((response) => {

                        props.listaHospedagens()
                        props.alertaSalvo()

                        window.$('.fecharModal').click()
                                                    
                    }).catch((err) => {
                        
                        props.alertaErro('salvar')

                        console.log(err.response)
                        return false

                    })


                }
                
            }
            
            salvaReg()

        })
       

    }


    function apagaHospedagemModal(){

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma exclusão?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1
        }).then(function(t) {

            async function apagaReg(){

                await api.delete(`hospedagem/${hospedagem_id}`).then((response) => {

                    props.alertaRemovido()

                    props.listaHospedagens() 
                    window.$('.fecharModal').click()          

                }).catch((err) => {

                    props.alertaErro('remover')
                    
                    console.log(err)

                })

            }

            apagaReg()

        })

    }


    useEffect(() => {

        carregaDados()
        listaClientes()

    }, [hospedagem_id])

    return (


            <div class="modal fade text-left-Hospedagem" 
					 tabindex="-1" 
                     role="dialog"    
                     aria-hidden="true" >
                
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header bg-secondary ">
                            <h4 class="modal-title" id="myModalLabel16"><i class="ft-user mr-2"></i>Hospedagem {props.hospedagem_id}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
                            </button>
                        </div>
                       
                        <div class="modal-body">
                        
                            <div class="form-row">

                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Cliente *</label>
                                        <select class="form-control" value={cliente_id} onChange={event => setCliente_id(event.target.value)}>
                                            <option value="">[SELECIONE]</option>
                                            {clientes}
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Url *</label>
                                        <input type="text" className="form-control" value={url} onChange={event => setUrl(event.target.value)} style={{ 'text-transform': 'uppercase' }}  />
                                    </div>
                                </div>

                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Url do Painel</label>
                                        <input type="text" className="form-control" value={painelUrl} onChange={event => setPainelUrl(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Usuário do Painel</label>
                                        <input type="text" className="form-control" value={painelUsuario} onChange={event => setPainelUsuario(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Senha do Painel</label>
                                        <input type="text" className="form-control" value={painelSenha} onChange={event => setPainelSenha(event.target.value)} />
                                    </div>
                                </div>


                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Url do Ftp</label>
                                        <input type="text" className="form-control" value={ftpUrl} onChange={event => setFtpUrl(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Usuário do Ftp</label>
                                        <input type="text" className="form-control" value={ftpUsuario} onChange={event => setFtpUsuario(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Senha do Ftp</label>
                                        <input type="text" className="form-control" value={ftpSenha} onChange={event => setFtpSenha(event.target.value)} />
                                    </div>
                                </div>


                                <div class="col-md-8 col-12">
                                    <div class="form-group mb-2">
                                        <label>Url do Banco</label>
                                        <input type="text" className="form-control" value={bancoUrl} onChange={event => setBancoUrl(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-4 col-12">
                                    <div class="form-group mb-2">
                                        <label>Porta do Banco</label>
                                        <input type="text" className="form-control" value={bancoPorta} onChange={event => setBancoPorta(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Usuário do Banco</label>
                                        <input type="text" className="form-control" value={bancoUsuario} onChange={event => setBancoUsuario(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Senha do Banco</label>
                                        <input type="text" className="form-control" value={bancoSenha} onChange={event => setBancoSenha(event.target.value)} />
                                    </div>
                                </div>


                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Usuário do Registro</label>
                                        <input type="text" className="form-control" value={registroUsuario} onChange={event => setRegistroUsuario(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12">
                                    <div class="form-group mb-2">
                                        <label>Senha do Registro</label>
                                        <input type="text" className="form-control" value={registroSenha} onChange={event => setRegistroSenha(event.target.value)} />
                                    </div>
                                </div>

                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Observação</label>
                                        <textarea rows="4" class="form-control" value={observacao} onChange={event => setObservacao(event.target.value)}></textarea>
                                    </div>
                                </div>


                                <div class="col-md-6 col-12" style={{ 'display': hospedagem_id ? 'table-row' : 'none' }}>
                                    <div class="form-group mb-2">
                                        <label>Data de cadastro</label>
                                        <input type="text" className="form-control" readonly="readonly" value={ad_new} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12" style={{ 'display': hospedagem_id ? 'table-row' : 'none' }}>
                                    <div class="form-group mb-2">
                                        <label>Última alteração</label>
                                        <input type="text" className="form-control" readonly="readonly" value={ad_upd} />
                                    </div>
                                </div>

                            </div>
                                
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn bg-light-grey fecharModal" data-dismiss="modal"><i class="ft-x mr-1"></i>Fechar</button>
                            <button type="button" class="btn btn-danger" onClick={() =>  apagaHospedagemModal()} style={{ 'display': hospedagem_id ? 'table-row' : 'none' }}><i class="ft-trash-2 mr-1"></i>Apagar</button>
                            <button type="button" class="btn btn-secondary" onClick={() => salva()}><i class="ft-check-square mr-1"></i>Salvar</button>                            
                        </div>

                    </div>
                </div>
            </div>


    )
}

export default Modal