import React, { useEffect, useState } from 'react'
import api from '../components/api'
import Modal from '../modals/clienteModal'


const Clientes = () => {

    var [clientes, setClientes] = useState('')
    var [cliente_id, setCliente_id] = useState('')

    async function listaClientes(){


        await api.get('clienteListaTodos').then((response) => {

            if (response.data) {

                console.log(response.data)

                
				setClientes(response.data.map((rs) => 

                    <tr>
						<td>{rs.nome}</td>
						<td>{rs.ad_new}</td>
                        <td>{rs.ad_upd}</td>

                        <td class="text-truncate">
                            <a class="success p-0"
                                data-toggle="modal" 
                                aria-haspopup="true" 
                                aria-expanded="false" 
                                data-target=".text-left-Cliente"
                                onClick={() => setCliente_id(rs.cliente_id)}
                                key={cliente_id}
                                >

                                <i class="ft-edit-2 font-medium-3 mr-2"></i>
                            </a>

                            <a class="danger p-0"
                                onClick={() => apagaCliente(rs.cliente_id)}
                                >
                                <i class="ft-x font-medium-3"></i>
                            </a>
                        </td>
                        
					</tr>))
            

        }
           

        }).catch((err) => {
            
            console.log(err)

        })

    


    }

    function apagaCliente(cliente_id){

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma exclusão?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
            
        }).then(function(t) {

            async function apagaReg(){

                await api.delete(`cliente/${cliente_id}`).then((response) => {
        
                    listaClientes()           
        
                }).catch((err) => {
                    
                    console.log(err)
        
                })
        
            }

            apagaReg()

        })

    }


    useEffect(() => {

        listaClientes()

    }, [])

    
    return (
        <div>

            

            <div class="content-overlay"></div>

            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="content-header">Clientes</div>
                    </div>
                </div>
                <section id="extended">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">

                                <button type="button" 
                                    class="btn btn-info mr-2" 
                                    data-toggle="modal" 
                                    aria-haspopup="true" 
                                    aria-expanded="false" 
                                    data-target=".text-left-Cliente"
                                    onClick={() => setCliente_id()}
                                    >

                                    <i class="ft-plus-square mr-1"></i> Incluir

                                </button>
                                
                                </div>
                                <div class="card-content">
                                    <div class="card-body table-responsive">
                                        <table class="table table-striped m-0">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Data de cadastro</th>
                                                <th>Última alteração</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {clientes}
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </section>
            </div>

            
            <Modal class="modal fade text-left-Cliente" 
                tabindex="-1" 
                role="dialog"    
                aria-hidden="true"
                listaClientes={listaClientes}
                cliente_id={cliente_id}
                />
            

        </div>
           
    )
}

export default Clientes