import React, { useEffect, useState } from 'react'
import axios from 'axios'
import api from '../components/api'

const Modal = (props) => {

    var erro = ''
    var erroMensagem = ''

    var cliente_id = props.cliente_id
    var [nome, setNome] = useState('')
    var [ad_new, setAd_new] = useState('')
    var [ad_upd, setAd_upd] = useState('')

    console.log('cliente_id: ' + props.cliente_id)


    async function carregaDados() {

        if (cliente_id) {

            await api.get(`clienteListaUm/${cliente_id}`).then((response) => {

                var data = response.data
    
                setNome(data[0].nome)
                setAd_new(data[0].ad_new)
                setAd_upd(data[0].ad_upd)

            }).catch((err) => {
                
                console.log(err.response)
    
            })

        } else {

            limpaDados()

        }


    }


    function limpaDados(){

        setNome('')
        setAd_new('')
        setAd_upd('')

    }

    function salva(){

        console.log(nome)

        if(nome === ''){
            erro = 'nome'
            erroMensagem = 'O campo "Nome" não pode ficar em branco!'
        }


        if(erro){

           window. Swal.fire({
                title: "Erro!",
                text: erroMensagem,
                type: "error",
                confirmButtonClass: "btn btn-secondary",
                buttonsStyling: !1
            })

            return false
        }  
        

        var dataPost = {
            nome: nome.toUpperCase()
        }

        

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma salvar?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1
        }).then(function(t) {
            
            console.log(dataPost)

            async function salvaReg() {

                if (cliente_id) {

                    await api.put(`cliente/${cliente_id}`, dataPost).then((response) => {

                        props.listaClientes()
                        limpaDados()
                        window.$('.fecharModal').click()
                                                    
                    }).catch((err) => {
                        
                        console.log(err.response)
                        return false

                    })

                }else{

                    await api.post('cliente', dataPost).then((response) => {

                        props.listaClientes()
                        limpaDados()
                        window.$('.fecharModal').click()
                                                    
                    }).catch((err) => {
                        
                        console.log(err.response)
                        return false

                    })


                }
                
            }
            
            salvaReg()

        })
       

    }


    function apagaClienteModal(){

        window.Swal.fire({
            title: "Confirmação",
            text: "Confirma exclusão?",
            type: "question",
            showCancelButton: !0,
            confirmButtonColor: "#2F8BE6",
            cancelButtonColor: "#F55252",
            confirmButtonText: "SIM",
            confirmButtonClass: "btn btn-secondary",
            cancelButtonText: "CANCELAR",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1
        }).then(function(t) {

            async function apagaReg(){

                await api.delete(`cliente/${cliente_id}`).then((response) => {

                    props.listaClientes() 
                    window.$('.fecharModal').click()          

                }).catch((err) => {
                    
                    console.log(err)

                })

            }

            apagaReg()

        })

    }


    useEffect(() => {

        carregaDados()

    }, [cliente_id])

    return (


            <div class="modal fade text-left-Cliente" 
					 tabindex="-1" 
                     role="dialog"    
                     aria-hidden="true" >
                
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header bg-secondary ">
                            <h4 class="modal-title" id="myModalLabel16"><i class="ft-user mr-2"></i>Cliente</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
                            </button>
                        </div>
                       
                        <div class="modal-body">
                        
                            <div class="form-row">

                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label>Nome</label>
                                        <input type="text" className="form-control" value={nome} onChange={event => setNome(event.target.value)} style={{ 'text-transform': 'uppercase' }}  />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12" style={{ 'display': cliente_id ? 'table-row' : 'none' }}>
                                    <div class="form-group mb-2">
                                        <label>Data de cadastro</label>
                                        <input type="text" className="form-control" readonly="readonly" value={ad_new} />
                                    </div>
                                </div>

                                <div class="col-md-6 col-12" style={{ 'display': cliente_id ? 'table-row' : 'none' }}>
                                    <div class="form-group mb-2">
                                        <label>Última alteração</label>
                                        <input type="text" className="form-control" readonly="readonly" value={ad_upd} />
                                    </div>
                                </div>

                            </div>
                                
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn bg-light-grey fecharModal" data-dismiss="modal"><i class="ft-x mr-1"></i>Fechar</button>
                            <button type="button" class="btn btn-danger" onClick={() =>  apagaClienteModal()} style={{ 'display': cliente_id ? 'table-row' : 'none' }}><i class="ft-trash-2 mr-1"></i>Apagar</button>
                            <button type="button" class="btn btn-secondary" onClick={() => salva()}><i class="ft-check-square mr-1"></i>Salvar</button>                            
                        </div>

                    </div>
                </div>
            </div>


    )
}

export default Modal