import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../components/api'
import Lottie from 'react-lottie';
import animationData from '../lottie/75657-uiux-design-icon-lottie-animation.json';

const Login = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }

    var [email, setEmail] = useState('')
    var [senha, setSenha] = useState('')
    var history = useHistory()

    useEffect(() => {

       
    }, [])


    async function login() {


        var dataPost = {

            email: email,
            senha: senha,

        }


        await api.post('login', dataPost).then((response) => {

            if (response.data[0].usuario_id) {

                var nome = response.data[0].nome

                localStorage.setItem("usuario_id", response.data[0].usuario_id);
                localStorage.setItem("nome", nome);
                localStorage.setItem("email", response.data[0].email);
                localStorage.setItem("perfilDescricao", response.data[0].perfilDescricao);
                localStorage.setItem("perfil_id", response.data[0].perfil_id);
                localStorage.setItem("email", response.data[0].email);
                localStorage.setItem("autenticado", true)
                
                window.toastr.success(`Seja bem vindo <b>${nome}!`)

                history.push('/Clientes')
                

            } else {

                window.toastr.error(response.data[0].alerta)
                return false

            }
                        
        }).catch((err) => {

            console.log(err.response)

        })

    }


    return (

        <body class="vertical-layout vertical-menu 1-column auth-page navbar-sticky blank-page" data-menu="vertical-menu" data-col="1-column">
        
            <section id="login" class="auth-height">
                <div class="row full-height-vh m-0">
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <div class="card overflow-hidden">
                        <div class="card-content">
                            <div class="card-body auth-img">
                            <div class="row m-0">
                                <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                
                                <Lottie 
                                        options={defaultOptions}
                                        height={230}
                                        width={300}
                                    />
                                </div>
                                <div class="col-lg-6 col-12 px-4 py-3">
                                    <h4 class="mb-2 card-title">Login</h4>

                                    

                                    <input type="text" class="form-control mb-3" placeholder="Email" onChange={event => setEmail(event.target.value)}/>

                                    <input type="password" class="form-control mb-2" placeholder="Senha" onChange={event => setSenha(event.target.value)}/>
                                    
                                    <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                                        <div class="remember-me mb-2 mb-sm-0">
                                        <div class="checkbox auth-checkbox">
                                            <input type="checkbox" id="auth-ligin"/>
                                            <label for="auth-ligin"><span>Lembrar-me</span></label>
                                        </div>
                                        </div>
                                        <a>Esqueceu a senha?</a>
                                    </div>
                                    <div class="d-flex justify-content-between flex-sm-row flex-column">
                                        <a class="btn btn-primary" onClick={() => login()}>Login</a>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        </body>
    
    )

}

export default Login